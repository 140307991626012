import { AfterViewInit, Component, OnInit } from '@angular/core';

import { AnalyticsService } from '@msslib/services/analytics.service';
import { IEmbedConfig, IMiReport } from '../mi-generic.model';
import { MiGenericService } from '../mi-generic.service';
import { PowerBiComponent } from '../../../../../../libs/msslib/src/lib/components/powerbi/powerbi.component';
import { FormsModule } from '@angular/forms';
import { NgFor, NgIf } from '@angular/common';
import { MiPageNavComponent } from '../mi-page-nav/mi-page-nav.component';

@Component({
  selector: 'app-mi-key-partner',
  styleUrls: [],
  templateUrl: 'mi-key-partner.component.html',
  standalone: true,
  imports: [
    MiPageNavComponent,
    NgIf,
    NgFor,
    FormsModule,
    PowerBiComponent,
  ],
})
export class MiKeyPartnerComponent implements OnInit, AfterViewInit {
  public miReports: IMiReport[];
  public embedConfig: IEmbedConfig;

  public constructor(
    private analyticsService: AnalyticsService,
    private miGenericService: MiGenericService,
  ) { }

  public ngOnInit() {
    this.miGenericService.getReports('GetKeyPartnerReports').subscribe(reports => {
      this.miReports = reports;
      if (reports.length && reports[0].id) {
        this.reportChange(reports[0].id);
      }
    });
  }

  public ngAfterViewInit() {
    this.analyticsService.log('Broker MI');
  }

  public reportChange(reportId: string) {
    this.miGenericService.getReportConfiguration(reportId, 'GetKeyPartnerConfigurationReports')
      .subscribe(config => {
        this.embedConfig = config;
      });
  }
}
