import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentPreferencesComponent, PageHeadingComponent } from '@msslib/components';

@Component({
  selector: 'app-document-preferences-page',
  templateUrl: 'document-preferences-page.component.html',
  standalone: true,
  imports: [PageHeadingComponent, DocumentPreferencesComponent],
})
export class DocumentPreferencesPageComponent {
  public constructor(
    public location: Location,
    public router: Router,
  ) {}
}
