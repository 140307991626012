import { APP_INITIALIZER, ErrorHandler, enableProdMode, importProvidersFrom } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Sentry from '../../../sentry';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { MssLibModule } from '@msslib/msslib.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MiGenericService } from './app/mi/mi-generic.service';
import { ConfigService } from '@msslib/services/config.service';
import { LenderHubService } from 'apps/shared/src/services/lenderhub.service';

if (environment.production) {
  enableProdMode();
  Sentry.init({
    dsn: 'https://3e473e063407406ba3dab22566cdcc66@sentry.theidol.com/64',
    attachStacktrace: true,
    debug: false,
    environment: 'production',
    maxValueLength: 5000,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, FormsModule, MssLibModule.forRoot(environment.mssLibConfig),
      ReactiveFormsModule),
    LenderHubService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => () => configService.load(),
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    MiGenericService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes, withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })),
  ],
})
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));
