import { Component, OnInit } from '@angular/core';
import { ClubHubDataService, ToastService } from '@msslib/services';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

export interface LenderDocument {
  lenderName: string;
  lenderNameId: number;
  fileName: string;
  description: string;
  uploadTime: number;
}

@Component({
  templateUrl: 'consumer-duty.component.html',
  styleUrls: ['consumer-duty.component.scss'],
  standalone: true,
  imports: [NgbModule],
})
export class ConsumerDutyComponent implements OnInit {

  public lendingType: string;
  public fields: FormlyFieldConfig[];
  public documents: LenderDocument[] = [];
  public lenderNames: string[] = [];
  public activeAccordions: string[] = [];

  public constructor(
    private clubHubDataService: ClubHubDataService,
    private toastService: ToastService,
  ) { }

  public ngOnInit(): void {
    this.getFiles();
  }

  public formatDate(date: string): string {
    return format(parseISO(date), 'dd/MM/yyyy HH:mm');
  }

  public getFiles() {
    this.documents = [];
    this.clubHubDataService.get('ConsumerDuty/DocumentList').subscribe({
      next: (result: LenderDocument[]) => {
        result.forEach((document: LenderDocument) => {
          // Push documents

          this.documents.push(document);
        });
      },
      error: () => {
        this.toastService.danger('Unable to get a list of files');
      },
      complete: () => {
        this.populateLenderNames();
      },
    });
  }

  public download(fileName: string, lenderNameId: number) {
    this.clubHubDataService.getFile(`ConsumerDuty/Documents?lenderNameId=${lenderNameId}` +
    `&fileName=${encodeURIComponent(fileName)}`).subscribe({
      next: (result: Blob) => {
        const fileURL = URL.createObjectURL(result);
        const a = document.createElement('a');
        a.href = fileURL;
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      error: () => {
        this.toastService.danger('Unable to download file');
      },
    });
  }

  public downloadAll(lenderName: string) {
    this.documents.forEach(document => {
      if (document.lenderName === lenderName) {
        this.download(document.fileName, document.lenderNameId);
      }
    });
  }

  public populateLenderNames() {
    this.documents.forEach(document => {
      if (!this.lenderNames.includes(document.lenderName)) {
        this.lenderNames.push(document.lenderName);
      }
    });
    this.lenderNames.sort();
  }

  public getDocumentsByLenderName(lenderName: string) {
    const lenderDocuments: LenderDocument[] = [];
    this.documents.forEach((document) => {
      if (document.lenderName === lenderName) {
        lenderDocuments.push(document);
      }
    });
    return lenderDocuments;
  }
}
