import { Component, OnInit } from '@angular/core';

import { AuthorizeService, ClubHubDataService, ConfigService, IUser, LenderHubDataService } from '@msslib/services';
import { clientIds, roles } from '@msslib/constants';
import { AnalyticsService } from '@msslib/services/analytics.service';
import { ClientApps } from '@msslib/models/enums';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgIf } from '@angular/common';
import { HeaderComponent as LibHeaderComponent } from '@msslib/components/header/header.component';
import { EMAIL_VERIFICATION_SERVICE } from '@msslib/models';
import { EmailVerificationService } from '@msslib/services/email-verification.service';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  standalone: true,
  imports: [
    LibHeaderComponent,
    NgIf,
    RouterLink,
    RouterLinkActive,
  ],
  providers: [EmailVerificationService, LenderHubDataService, ClubHubDataService,
    {
      provide: EMAIL_VERIFICATION_SERVICE,
      useClass: EmailVerificationService,
    },
  ],
})
export class HeaderComponent implements OnInit {
  public roles = roles;
  public showNav: boolean;
  public clientApp: ClientApps = ClientApps.ClubHub;

  public constructor(
    public authService: AuthorizeService,
    private configService: ConfigService,
    private analyticsService: AnalyticsService,
  ) {}

  public ngOnInit() {
    this.authService.getUser().subscribe((user: IUser) => {
      this.showNav = !this.authService.historyView || !!user;
    });
  }

  public hasRole(role: string) {
    return this.authService.hasRole(role);
  }

  public ignite() {
    this.analyticsService.trackClick('Ignite');
  }

  public get isSimplyBiz() {
    return this.authService.clientId === clientIds.simplyBiz;
  }

  public get hasAccessToMi() {
    return [roles.brokerMi,
      roles.brokerIntegrationMi,
      roles.executiveMarketSummaryMI,
      roles.brokerPaidMI,
      roles.brokerIgniteMI,
      roles.brokerNewKeyPartnerMI].some(r => this.hasRole(r));
  }

  public get isS365() {
    return this.authService.isS365Client;
  }

  public get smartrSurveyLink(): string | undefined {
    if (!this.authService.user?.agencyNumber) {
      return;
    }
    return `${this.configService.config.smartrSurveyLink}?agency-number=${encodeURIComponent(
      this.authService.user.agencyNumber,
    )}&firm-name=${encodeURIComponent(this.authService.user.firmName)}`;
  }

  public get firstMiLink() {
    if (this.hasRole(roles.brokerMi)) {
      return '/mi';
    } else if (this.hasRole(roles.brokerIntegrationMi)) {
      return '/mi/integration';
    } else if (this.hasRole(roles.brokerNewKeyPartnerMI)) {
      return '/mi/key-partner';
    } else if (this.hasRole(roles.executiveMarketSummaryMI)) {
      return '/mi/executive-market-summary';
    } else if (this.hasRole(roles.brokerIgniteMI)) {
      return '/mi/broker-ignite';
    }
    return null;
  }
}
