<h3 id="banner" class="p-2 text-center">Fair Value</h3>
<div class="text-left justify-content-center d-flex p-3 m-3">
  <div ngbAccordion style="width: 50%;" [closeOthers]="true">
    @for (lenderName of lenderNames; track lenderName) {
      <div ngbAccordionItem>
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton>{{lenderName}}</button>
        </h2>
        <!-- Main accordion content -->
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <table class="table align-middle">
                <thead>
                  <tr>
                    <th>
                      Description
                    </th>
                    <th class="text-end">
                      Last Updated
                    </th>
                  </tr>
                </thead>
                <tbody>
                  @for (document of getDocumentsByLenderName(lenderName); track document) {
                    <tr>
                      <td>
                        <a class="link" (click)="download(document.fileName, document.lenderNameId)">{{document.description}}</a>
                      </td>
                      <td class="text-end">
                        {{ formatDate(document.uploadTime) }}
                      </td>
                    </tr>
                  }
                  <tr class="text-end">
                    <td></td>
                    <td>
                      <button class="btn btn-2022--secondary"
                        data-testid="downloadAllBtn"
                        (click)="downloadAll(lenderName)">
                        Download All
                        <i class="fas fa-download ms-2"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </div>
        </div>
      </div>
    }
  </div>
</div>
