import { Component } from '@angular/core';
import { UserCustomProductSettingsComponent } from '@msslib/components';
import { PageHeadingComponent } from '@msslib/components/page-heading/page-heading.component';

@Component({
  selector: 'app-user-custom-product-settings-page',
  templateUrl: './user-custom-product-settings-page.component.html',
  standalone: true,
  imports: [PageHeadingComponent, UserCustomProductSettingsComponent],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class UserCustomProductSettingsPageComponent {

}
