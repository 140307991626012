import { Component } from '@angular/core';
import { AuthorizeService } from '@msslib/services/authorize.service';
import { roles } from '@msslib/constants';
import { NgIf } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-mi-page-nav',
  styleUrls: ['mi-page-nav.component.scss'],
  templateUrl: 'mi-page-nav.component.html',
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    RouterLinkActive,
  ],
})
export class MiPageNavComponent {
  public constructor(private authorizeService: AuthorizeService) {}

  public get hasBrokerIntegrationMi() {
    return this.authorizeService.hasRole(roles.brokerIntegrationMi);
  }

  public get hasBrokerMi() {
    return this.authorizeService.hasRole(roles.brokerMi);
  }

  public get hasBrokerNewKeyPartnerMI() {
    return this.authorizeService.hasRole(roles.brokerNewKeyPartnerMI);
  }

  public get hasBrokerIgniteMI() {
    return this.authorizeService.hasRole(roles.brokerIgniteMI);
  }

  public get hasExclusiveMarketSummaryMI() {
    return this.authorizeService.hasRole(roles.executiveMarketSummaryMI);
  }
}
